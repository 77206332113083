<template>
  <form class="login-forgot-password" @submit.prevent="onSubmit" novalidate autocomplete="off">
    <div class="login-forgot-password__title">
      <ui-title :title="$t('login.forgotPassword.title')" align="center" />
    </div>
    <div class="login-forgot-password__description">
      {{ $t('login.forgotPassword.description') }}
    </div>
    <ui-input
      class="login-forgot-password__input"
      v-model="email"
      type="email"
      id="email"
      :label="$t('login.forgotPassword.email')"
      icon="mail_outline"
      :reverse="true"
      :error="$v.email.$error"
      design="minimalist"
    >
      <template v-if="$v.email.$error && !$v.email.required">
        {{ $t('errors.required') }}
      </template>
      <template v-else-if="$v.email.$error && !$v.email.email">
        {{ $t('errors.email') }}
      </template>
    </ui-input>
    <ui-button
      class="login-forgot-password__submit"
      button="cta"
      type="submit"
      :label="$t('login.forgotPassword.submit')"
      variant="gtr"
      :disabled="sending"
    />
    <div class="login-forgot-password__bottom">
      <ui-link :path="{ name: 'SignIn' }">
        {{ $t('login.forgotPassword.signIn') }}
      </ui-link>
    </div>
  </form>
</template>

<script>
import UiTitle from '@/components/UI/Title.vue'
import UiInput from '@/components/UI/Input.vue'
import UiLink from '@/components/UI/Link.vue'
import UiButton from '@/components/UI/Button.vue'
import { notification } from '@/utils/notification.util'

import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'LoginForgotPassword',
  components: {
    UiTitle,
    UiInput,
    UiLink,
    UiButton,
  },
  data() {
    return {
      email: '',
      sending: false,
    }
  },
  methods: {
    ...mapActions({
      userForgetPassword: 'user/userForgetPassword',
    }),
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sending = true
        this.userForgetPassword({ email: this.email })
          .then(() => {
            notification({
              text: this.$t('login.forgotPassword.notification.success'),
              type: 'success',
            })
            this.$router.push({ name: 'SignIn' })
          })
          .catch(() => {
            this.sending = false
            notification({
              text: this.$t('common.notification.error'),
              type: 'error',
            })
          })
      }
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.login-forgot-password {
  &__title,
  &__description {
    display: flex;
    justify-content: center;
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__description {
    text-align: center;
  }

  &__input {
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__submit {
    margin-bottom: $gutter-tablet;
    width: 100%;

    @media (min-width: $screen-xl) {
      margin-bottom: $gutter-desktop;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__caption {
      margin-bottom: $gutter-tablet;
      text-align: center;
      color: $generic-color-text-caption;

      @media (min-width: $screen-xl) {
        margin-bottom: $gutter-desktop;
      }
    }
  }
}
</style>
